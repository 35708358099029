import * as React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
    <Layout>
        <SEO title="404: Not found" />

        <div
            style={{
                maxWidth: '720px',
                padding: '1rem',
                margin: '0 auto',
                marginTop: '3rem',
            }}
        >
            <h1>NOT FOUND</h1>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </div>
    </Layout>
);

export default NotFoundPage;
